import { useDispatch, useSelector } from "react-redux";
import { Button, DialogContent } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";

import ActivityDateFilter from "./ActivityDateFilter";
import ActivityOptionFilter from "./ActivityOptionFilter";
import { getActivityHistory } from "../../../thunks/dashboardThunks";
import { getActivityQueryParams } from "../../../utils/urlUtils";
import {
  TypographyCapitalizeStyles,
  IconButtonStyles,
  BoxContentStyles,
} from "../../../styles/commonStyles";
import {
  DialogStyles,
  DialogTitleStyles,
  FilterStyles,
} from "../../../styles/activityStyles";
import {
  IS_HIDDEN_OPTIONS,
  ACTIVITY_TYPES,
} from "../../../constants/appUtilsConstants";
import {
  clearActivityHistory,
  filterDialogSelector,
  toggleFilterMenu,
  updateDateRange,
  updateIsHidden,
  updateActivityType,
  resetFilterSettings,
} from "../../../store/slices/activityHistorySlice";

const ActivityFilterDialog = () => {
  const dispatch = useDispatch();
  const { filterSettings, orderSettings, pageSettings } =
    useSelector(filterDialogSelector);
  const { hasFilterOpened } = useSelector(filterDialogSelector);

  const { isHidden, activityType, dateRange } = filterSettings;

  const handleSubmitFilter = () => {
    const queryParams = getActivityQueryParams({
      filterSettings,
      orderSettings,
      pageSettings,
    });
    dispatch(clearActivityHistory());
    dispatch(getActivityHistory(queryParams));
    dispatch(toggleFilterMenu());
  };

  return (
    <DialogStyles
      open={hasFilterOpened}
      onClose={() => dispatch(toggleFilterMenu())}
    >
      <DialogTitleStyles>
        <FilterAltIcon />
        <TypographyCapitalizeStyles variant="h6" noWrap component="div">
          filter
        </TypographyCapitalizeStyles>
        <IconButtonStyles
          onClick={() => {
            dispatch(toggleFilterMenu());
          }}
        >
          <CloseIcon />
        </IconButtonStyles>
      </DialogTitleStyles>
      <DialogContent dividers>
        <FilterStyles>
          <ActivityOptionFilter
            id="type-filter"
            filter={activityType}
            handleFilter={(event, newTypeFilter) =>
              dispatch(updateActivityType(newTypeFilter))
            }
            label="Activity Type"
            optionList={ACTIVITY_TYPES}
          />
          <ActivityOptionFilter
            id="hidden-filter"
            filter={isHidden}
            handleFilter={(event, newHiddenFilter) =>
              dispatch(updateIsHidden(newHiddenFilter))
            }
            label="Is Hidden"
            optionList={IS_HIDDEN_OPTIONS}
          />
          <ActivityDateFilter
            handleDateFilter={changeDate =>
              dispatch(updateDateRange(changeDate))
            }
            dateRange={dateRange}
          />
          <BoxContentStyles>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(resetFilterSettings());
                dispatch(getActivityHistory());
              }}
            >
              reset
            </Button>
            <Button variant="contained" onClick={handleSubmitFilter}>
              filter
            </Button>
          </BoxContentStyles>
        </FilterStyles>
      </DialogContent>
    </DialogStyles>
  );
};

export default ActivityFilterDialog;
