/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { Box, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";

import { BoxDateStyles } from "../../../styles/activityStyles";
import { TypographyCapitalizeStyles } from "../../../styles/commonStyles";
import {
  DATE_PICKER_FORMAT,
  FILTER_INITIAL_STATE,
} from "../../../constants/appUtilsConstants";

const ActivityDateFilter = ({ handleDateFilter, dateRange }) => {
  const { fromStartDate, toEndDate } = dateRange;
  const theme = useTheme();

  return (
    <Box>
      <TypographyCapitalizeStyles color={theme.palette.primary.main}>
        filter by date happened
      </TypographyCapitalizeStyles>
      <Box>
        <BoxDateStyles>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="From Start Date"
              value={!fromStartDate ? fromStartDate : new Date(fromStartDate)}
              onChange={value =>
                handleDateFilter({
                  value: format(value, DATE_PICKER_FORMAT),
                  name: "fromStartDate",
                })
              }
              renderInput={params => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    value: fromStartDate || "",
                  }}
                />
              )}
              maxDate={toEndDate ? new Date(toEndDate) : null}
            />
          </LocalizationProvider>
        </BoxDateStyles>
        <BoxDateStyles>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="To End Date"
              value={!toEndDate ? toEndDate : new Date(toEndDate)}
              onChange={value =>
                handleDateFilter({
                  value: format(value, DATE_PICKER_FORMAT),
                  name: "toEndDate",
                })
              }
              renderInput={params => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    value: toEndDate || "",
                  }}
                />
              )}
              minDate={fromStartDate ? new Date(fromStartDate) : null}
            />
          </LocalizationProvider>
        </BoxDateStyles>
      </Box>
    </Box>
  );
};

ActivityDateFilter.defaultProps = {
  dateRange: FILTER_INITIAL_STATE.dateRange,
};

ActivityDateFilter.propTypes = {
  dateRange: PropTypes.shape({
    fromStartDate: PropTypes.string,
    toEndDate: PropTypes.string,
  }),
  handleDateFilter: PropTypes.func.isRequired,
};

export default ActivityDateFilter;
