import { combineReducers } from "@reduxjs/toolkit";

import activityHistoryReducer from "./slices/activityHistorySlice";
import totalWinningsReducer from "./slices/totalWinningsSlice";
import pointsBalanceReducer from "./slices/pointsBalanceSlice";
import sessionSlice from "./slices/sessionSlice";
// eslint-disable-next-line max-len
import sweepsParticipationStatusReducer from "./slices/sweepsParticipationStatusSlice";
import sweepstakesReducer from "./slices/sweepstakesSlice";
import pointsAdjustmentReducer from "./slices/pointsAdjustmentSlice";

const rootReducer = combineReducers({
  totalWinnings: totalWinningsReducer,
  activity: activityHistoryReducer,
  session: sessionSlice,
  pointsBalance: pointsBalanceReducer,
  sweepsParticipationStatus: sweepsParticipationStatusReducer,
  sweepstakes: sweepstakesReducer,
  pointsAdjustment: pointsAdjustmentReducer,
});

export default rootReducer;
