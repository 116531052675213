import PropTypes from "prop-types";
import camelCase from "lodash/camelCase";
import PersonIcon from "@mui/icons-material/Person";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import SchoolSharpIcon from "@mui/icons-material/SchoolSharp";
import ModeCommentRoundedIcon from "@mui/icons-material/ModeCommentRounded";

import { ACTIVITY_TYPES } from "../../../constants/appUtilsConstants";

const ActivityTypeMarker = ({ type }) => {
  const activityTypeMarker = {
    accountStatus: PersonIcon,
    archived: PaymentsOutlinedIcon,
    bonus: CheckCircleSharpIcon,
    quiz: QuestionMarkOutlinedIcon,
    prize: AttachMoneyOutlinedIcon,
    survey: ModeCommentRoundedIcon,
    sweeps: CampaignOutlinedIcon,
    tutorial: SchoolSharpIcon,
  };

  const handler = {
    get: (target, name) =>
      Object.prototype.hasOwnProperty.call(target, name)
        ? target[name]
        : PaymentsOutlinedIcon,
  };

  const activityTypeMarkerProxy = new Proxy(activityTypeMarker, handler);

  const MarkerContents = activityTypeMarkerProxy[type];
  return <MarkerContents />;
};

ActivityTypeMarker.defaultProps = {
  type: "other",
};

ActivityTypeMarker.propTypes = {
  type: PropTypes.oneOf(ACTIVITY_TYPES.map(category => camelCase(category))),
};

export default ActivityTypeMarker;
