import { Helmet } from "react-helmet";

import Layout from "../components/common/Layout";
import { PAGE_TITLES } from "../constants/appUtilsConstants";

const NotFound = () => (
  <Layout>
    <Helmet>
      <title>{PAGE_TITLES.error}</title>
    </Helmet>
    <h1>Not Found</h1>
  </Layout>
);

export default NotFound;
