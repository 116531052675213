export const REQ_HEADERS = {
  ppSiteId: "PP-SITE-IDENTIFIER",
};

export const TOTAL_WINNINGS_URI = "/api/support/sweeps/lifetime-winnings/";
export const TOTAL_POINTS_URI = "/api/support/activity/points/";
export const POINTS_ADJUSTMENT_URI = "/api/support/activity/points-adjustment/";
// eslint-disable-next-line max-len
export const SWEEPS_PARTICIPATION_URI =
  "/api/support/sweeps/participation-status/";
export const SESSION_ARRIVED_URI = "/api/session/arrived/";
export const ACTIVITY_HISTORY_URI = "/api/support/activity/";
export const SWEEPSTAKES_URI = "/api/support/sweeps/";
