import { createSlice } from "@reduxjs/toolkit";

import { REQ_STATUSES } from "../../constants/appUtilsConstants";
import { totalWinningsErrorMessage } from "../../constants/toastMessages";
import { getTotalWinnings } from "../../thunks/dashboardThunks";
import { raiseErrorToast } from "../../utils/toastUtils";

const initialState = {
  totalWinnings: 0,
  hasErrors: false,
  lastFetchedOn: "",
  status: REQ_STATUSES.idle,
};

const getTotalWinningsPending = state => ({
  ...state,
  status: REQ_STATUSES.loading,
  hasErrors: false,
});

const getTotalWinningsFulfilled = (
  state,
  { internal_status_code: statusCode, data, errors }
) => {
  if (statusCode === 200) {
    state.status = REQ_STATUSES.succeeded;
    state.hasErrors = false;
    state.lastFetchedOn = new Date().toLocaleString();
    state.totalWinnings = data["lifetime_winnings"];
  } else if (errors) {
    state.status = REQ_STATUSES.failed;
    state.hasErrors = true;

    raiseErrorToast(totalWinningsErrorMessage);
  }

  return state;
};

const getTotalWinningsRejected = state => {
  raiseErrorToast(totalWinningsErrorMessage);
  return { ...state, hasErrors: true, status: REQ_STATUSES.failed };
};

const totalWinningsSlice = createSlice({
  name: "totalWinnings",
  initialState,
  reducers: {},
  extraReducers: {
    [getTotalWinnings.pending]: state => getTotalWinningsPending(state),
    [getTotalWinnings.fulfilled]: (state, { payload }) =>
      getTotalWinningsFulfilled(state, payload),
    [getTotalWinnings.rejected]: state => getTotalWinningsRejected(state),
  },
});

export const totalWinningsSelector = state => state.totalWinnings;

export default totalWinningsSlice.reducer;
