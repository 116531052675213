import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useDispatch, useSelector } from "react-redux";

import ActivityFilterDialog from "./ActivityFilterDialog";
import {
  activityStatusSelector,
  toggleFilterMenu,
} from "../../../store/slices/activityHistorySlice";
import {
  WidgetBarStyles,
  IconButtonStyles,
  WidgetBarHeadingStyles,
  TypographyCapitalizeStyles,
} from "../../../styles/commonStyles";
import { REQ_STATUSES } from "../../../constants/appUtilsConstants";

const ActivityTableBar = () => {
  const dispatch = useDispatch();
  const activityStatus = useSelector(activityStatusSelector);

  const iconButtonHandler = () => {
    if (activityStatus === REQ_STATUSES.succeeded) dispatch(toggleFilterMenu());
  };
  return (
    <WidgetBarStyles>
      <WidgetBarHeadingStyles variant="h6" noWrap component="div">
        activities
      </WidgetBarHeadingStyles>
      <IconButtonStyles onClick={iconButtonHandler}>
        <FilterAltIcon />
        <TypographyCapitalizeStyles>filters</TypographyCapitalizeStyles>
      </IconButtonStyles>
      <ActivityFilterDialog />
    </WidgetBarStyles>
  );
};

export default ActivityTableBar;
