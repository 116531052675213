import {
  Box,
  Card,
  Grid,
  IconButton,
  Skeleton,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const WidgetBarStyles = styled(Toolbar)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.backgroundColor,
}));

export const TypographyCapitalizeStyles = styled(Typography)(() => ({
  textTransform: "capitalize",
}));

export const IconButtonStyles = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.primary.main,
}));

export const BoxContentStyles = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3.75),
  display: "flex",
  justifyContent: "space-evenly",
}));

export const WidgetBarHeadingStyles = styled(TypographyCapitalizeStyles)(
  () => ({
    flexGrow: 1,
  })
);

export const TablePaginationStyles = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: theme.spacing(2.5),
}));

export const CardBody = styled(Grid)(() => ({
  lineHeight: "normal",
  verticalAlign: "middle",
  display: "inline-block",
  textAlign: "center",
}));

export const CenterGrid = styled(Grid)({
  minHeight: "100vh",
  padding: "10px",
});

export const InfoCard = styled(Card)(({ backgroundcolor, theme }) => ({
  minWidth: "inherit",
  borderRadius: 4,
  background: backgroundcolor,
  minHeight: 200,
  marginBottom: theme.spacing(2),
}));

export const CardGrid = styled(Grid)(() => ({
  padding: "15px",
  alignItems: "center",
}));

export const CardHeadingIcon = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.between("xs", "lg")]: {
    textAlign: "center",
  },
}));

export const CardHeading = styled(Grid)(({ theme }) => ({
  lineHeight: "60px",
  [theme.breakpoints.between("xs", "lg")]: {
    textAlign: "center",
  },
}));

export const CardHeadingText = styled(Typography)(({ theme }) => ({
  lineHeight: "normal",
  verticalAlign: "middle",
  display: "inline-block",
  [theme.breakpoints.between("xs", "lg")]: {
    textAlign: "center",
    paddingLeft: "15px",
  },
}));

export const CardBodyText = styled(Typography)(() => ({
  lineHeight: "normal",
  verticalAlign: "middle",
  display: "inline-block",
  textAlign: "center",
}));

export const CardBodyFooter = styled(Grid)(() => ({
  placeContent: "flex-end",
  lineHeight: "20px",
}));

export const CardSkeleton = styled(Skeleton)(() => ({
  width: "inherit",
  height: "55px",
}));

export const IconStyles = {
  borderRadius: 10,
  alignItems: "center",
  justifyContent: "center",
  fontSize: "xxx-large",
};
