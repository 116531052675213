import {
  Table,
  TableHead,
  TableFooter,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Layout from "../components/common/Layout";
import TableContentContainer from "../components/common/TableContent";
import TableHeaders from "../components/common/TableHeaders";
// eslint-disable-next-line max-len
import TablePaginationControls from "../components/common/TablePaginationControls";
import SweepsTableRow from "../components/sweeps/SweepsTableRow";
import {
  PAGE_TITLES,
  REQ_STATUSES,
  SWEEPSTAKES_TABLE_HEADERS,
} from "../constants/appUtilsConstants";
import {
  sweepstakesSelector,
  updatePageNumber,
  updatePageSize,
} from "../store/slices/sweepstakesSlice";
import { getSweepstakes } from "../thunks/sweepstakesThunk";
import { getPageParams } from "../utils/urlUtils";

const Sweeps = () => {
  const dispatch = useDispatch();
  const { sweepsData, status, sweepstakesCount, pageSettings } =
    useSelector(sweepstakesSelector);

  const { page, perPage } = pageSettings;

  useEffect(() => {
    dispatch(getSweepstakes(getPageParams(pageSettings)));
  }, [pageSettings]);

  return (
    <Layout pageTitle={PAGE_TITLES.sweeps}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableHeaders headers={SWEEPSTAKES_TABLE_HEADERS} />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableContentContainer
              CustomTableRow={SweepsTableRow}
              isLoading={status === REQ_STATUSES.loading}
              data={sweepsData}
              id="id"
              rows={perPage}
              colSpan={8}
            />
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[3, 5, 7, 15]}
                colSpan={6}
                count={status === REQ_STATUSES.succeeded ? sweepstakesCount : 0}
                rowsPerPage={perPage}
                page={status === REQ_STATUSES.succeeded ? page - 1 : 0}
                onPageChange={(event, newPage) =>
                  dispatch(updatePageNumber(newPage))
                }
                onRowsPerPageChange={event => {
                  // eslint-disable-next-line no-unused-expressions
                  status === REQ_STATUSES.succeeded &&
                    dispatch(updatePageSize(parseInt(event.target.value, 10)));
                }}
                ActionsComponent={TablePaginationControls}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Layout>
  );
};

export default Sweeps;
