const PP_CSR_PREFIX = "PP_CSR__";

const JWT_KEY = `${PP_CSR_PREFIX}JWT`;
const CARD_HOLDER_ID = `${PP_CSR_PREFIX}CARD_HOLDER_ID`;
const CSR_ROLE = `${PP_CSR_PREFIX}ROLE`;
const IS_ACTIVE_PAYPERKS_USER = `${PP_CSR_PREFIX}IS_ACTIVE_PAYPERKS_USER`;
const PP_CSR_REFERRER = `${PP_CSR_PREFIX}REFERRER`;

export {
  JWT_KEY,
  CARD_HOLDER_ID,
  CSR_ROLE,
  IS_ACTIVE_PAYPERKS_USER,
  PP_CSR_REFERRER,
};
