export const PP_CSR_SITE_IDENTIFIER = "pp-csr";
export const CSR_ROLE_HEADER = "CSR-ROLE";

export const PAGE_TITLES = {
  dashboard: "Dashboard",
  sweeps: "Upcoming Sweepstakes",
  login: "Login",
  error: "Error",
  pointsAdjustment: "Points Adjustment",
};

export const NAV_LINKS = [
  {
    id: "dashboard",
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    id: "sweepstakes-timeline",
    title: "Sweepstakes Timeline",
    link: "/sweepstakes",
  },
];

export const ADMIN_NAV_LINKS = [
  {
    id: "create-points-adjustment",
    title: "Points Adjustment",
    link: "/points-adjustment",
  },
];

export const ACTIVITY_TYPES = [
  "Adjustment Correction",
  "User Account Creation",
  "User Account Deactivation",
  "User Account Reactivation",
  "Sweeps Entry",
  "Sweeps Ineligible User Points Expiration",
  "Sweeps Drawn Loser",
  "Sweeps Drawn Opted Out Loser",
  "Sweeps Prize Winner",
  "Sweeps Prize Claimed",
  "Sweeps Prize Expired",
  "Sweeps Opt In",
  "Sweeps Opt Out",
  "Sweeps Opt In Again",
  "Content Tutorial Completion",
  "Content Quiz Completion",
  "Content Survey Completion",
  "Bonus",
  "Pos Trx Counter",
  "All Load Dollar Counter",
  "Non State Load Dollar Counter",
  "Notif Mobile Number Updated Counter",
  "Notif Address Updated Counter",
  "Notif Password Updated Counter",
  "Notif Security Questions Updated Counter",
  "Notif Login Failed And Account Locked Counter",
  "Notif Account Unlocked Counter",
  "Notif Daily Available Balance Counter",
  "Notif Transaction Pre Auth Counter",
  "Notif Declined Transactions Counter",
  "Notif Value Load Counter",
  "Added Security Question Counter",
  "Added Mobile Wallet Counter",
  "Added Secondary Card Holder Counter",
  "Signed E Sign Agreement Counter",
  "Amoe Submission",
  "Amoe Sweeps Entry",
  "Csr Welcome Points",
  "Csr Missing Pos Adjustment",
  "Csr Misc Apology",
  "Csr Non Shared Secondary Card Adjustment",
  "Csr Other Adjustment",
];

export const ACTIVITY_TABLE_HEADERS = {
  activityId: { id: "id", label: "ID", sortEnabled: true },
  activityType: {
    id: "activity_type",
    label: "Activity Type",
    sortEnabled: false,
  },
  dateActivity: {
    id: "date_activity_happened",
    label: "Date Happened",
    sortEnabled: true,
  },
  points: { id: "points", label: "Points", sortEnabled: true },
  hidden: { id: "is_hidden", label: "Hidden?", sortEnabled: false },
};

export const ACTIVITY_SUB_TABLE_HEADERS = {
  transactionCode: { id: "transaction_code", label: "Transaction Code" },
  galileoId: { id: "galileo_program_id", label: "Galileo Program Id" },
  authCode: { id: "authorization_code", label: "Authorization Code" },
  authSequence: {
    id: "auth_sequence",
    label: "Auth Sequence",
  },
  postedDate: { id: "posted_date", label: "Posted Date" },
};

export const IS_HIDDEN_OPTIONS = ["All", "True", "False"];

export const FILTER_INITIAL_STATE = {
  activityType: "",
  isHidden: IS_HIDDEN_OPTIONS[0],
  dateRange: {
    fromStartDate: null,
    toEndDate: null,
  },
};

export const PAGE_SETTINGS = {
  page: 1,
  perPage: 5,
};

export const ORDER_TYPE = {
  ASCENDING: "asc",
  DESCENDING: "desc",
};

export const ORDER_SETTINGS = {
  order: ORDER_TYPE.DESCENDING,
  orderBy: ACTIVITY_TABLE_HEADERS.dateActivity.id,
};

// request status values
export const REQ_STATUSES = {
  idle: "idle",
  loading: "loading",
  succeeded: "succeeded",
  failed: "failed",
};

// request methods types
export const REQUEST_METHODS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const API_RESPONSE_CODES = {
  ERROR: 40101,
  SUCCESS: [201, 200],
};

export const AUTH_API_QUERY_PARAMS = ["jwt", "csr_role", "external_user_id"];

export const ACTIVITIES_CACHE_LIMIT = 60;

export const DATE_FORMAT = "MMMM dd, yyyy";
export const DATE_PICKER_FORMAT = "yyyy-MM-dd";

export const SWEEPSTAKES_TABLE_HEADERS = {
  sweepsId: { id: "id", label: "ID", sortEnabled: false },
  sweepStage: {
    id: "sweep_stage",
    label: "Sweepstakes Stage",
    sortEnabled: false,
  },
  startDate: {
    id: "start_date",
    label: "Start Date",
    sortEnabled: false,
  },
  endDate: {
    id: "end_date",
    label: "End Date",
    sortEnabled: false,
  },
  prizePool: {
    id: "prize_pool",
    label: "Prize Pool",
    sortEnabled: false,
  },
  maxPointPerUser: {
    id: "max_point_per_user",
    label: "Max Points Per User",
    sortEnabled: false,
  },
};

export const SWEEPSTAKES_SUB_TABLE_HEADERS = {
  valueInDollars: {
    id: "value_in_dollars",
    label: "Value In Dollars",
    sortEnabled: false,
  },
  prizesToAward: {
    id: "number_of_prizes_to_award",
    label: "Number of Available Prizes",
    sortEnabled: false,
  },
};

export const SWEEPS_PARTICIPATION_STATUS = {
  optedIn: "opted-in",
  optedOut: "opted-out",
};

export const CSR_ROLES = {
  operator: "operator",
  administrator: "administrator",
};

export const ONBOARDING_PAYLOAD = {
  notes: "",
  pointsOffered: 100,
  reason: "csr-welcome",
};
