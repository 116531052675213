import { Skeleton } from "@mui/material";
import PropTypes from "prop-types";

import { REQ_STATUSES } from "../../../constants/appUtilsConstants";
import {
  CardBody,
  CardBodyFooter,
  CardBodyText,
  CardGrid,
  CardHeading,
  CardHeadingIcon,
  CardHeadingText,
  CardSkeleton,
  InfoCard,
} from "../../../styles/commonStyles";

const DashboardCard = ({
  backgroundcolor,
  cardIcon,
  cardHeadingText,
  cardBodyText,
  cardFooterElement,
  status,
}) => (
  <InfoCard backgroundcolor={backgroundcolor}>
    <CardGrid container>
      <CardHeadingIcon item xs={12} sm={12} md={3}>
        {status === REQ_STATUSES.loading ? (
          <Skeleton width="35px" height="55px" />
        ) : (
          <div>{cardIcon}</div>
        )}
      </CardHeadingIcon>
      <CardHeading item xs={12} sm={12} md={9}>
        {status === REQ_STATUSES.loading ? (
          <CardSkeleton />
        ) : (
          <CardHeadingText variant="h6">{cardHeadingText}</CardHeadingText>
        )}
      </CardHeading>
      <CardBody item xs={12}>
        {status === REQ_STATUSES.loading ? (
          <CardSkeleton />
        ) : (
          <CardBodyText variant="h3">{cardBodyText}</CardBodyText>
        )}
      </CardBody>
    </CardGrid>
    <CardBodyFooter container p={2}>
      {status === REQ_STATUSES.loading ? (
        <Skeleton width="inherit" />
      ) : (
        <div>{cardFooterElement}</div>
      )}
    </CardBodyFooter>
  </InfoCard>
);

DashboardCard.propTypes = {
  backgroundcolor: PropTypes.string,
  cardHeadingText: PropTypes.string.isRequired,
  cardBodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  cardIcon: PropTypes.node.isRequired,
  // eslint-disable-next-line react/require-default-props
  cardFooterElement: PropTypes.node,
  status: PropTypes.oneOf(Object.values(REQ_STATUSES)).isRequired,
};

DashboardCard.defaultProps = {
  backgroundcolor: "white",
};

export default DashboardCard;
