import { createSlice } from "@reduxjs/toolkit";

import { REQ_STATUSES } from "../../constants/appUtilsConstants";
import { authUser } from "../../thunks/sessionThunks";
import {
  getIsActivePayperksUser,
  isValidSession,
} from "../../utils/storageUtils";

const initialState = {
  isAuthed: isValidSession(),
  status: REQ_STATUSES.idle,
  errorDetail: null,
  isActivePayperksUser: getIsActivePayperksUser(),
};

const getSessionPending = state => ({
  ...state,
  status: REQ_STATUSES.loading,
});

const getSessionFulfilled = state => ({
  ...state,
  isAuthed: true,
  status: REQ_STATUSES.succeeded,
  isActivePayperksUser: getIsActivePayperksUser(),
});

const getSessionRejected = (state, action) => ({
  ...state,
  errorDetail: action.error.message,
  status: REQ_STATUSES.failed,
});

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    updateIsActivePayperksUser: state => {
      state.isActivePayperksUser = true;
      return state;
    },
  },
  extraReducers: {
    [authUser.pending]: state => getSessionPending(state),
    [authUser.fulfilled]: state => getSessionFulfilled(state),
    [authUser.rejected]: (state, action) => getSessionRejected(state, action),
  },
});

export const sessionSelector = state => state.session;
export const { updateIsActivePayperksUser } = sessionSlice.actions;
export default sessionSlice.reducer;
