export const totalWinningsErrorMessage =
  "Error occurred while fetching user total lifetime Winnings";
export const sweepsParticipationErrorMessage =
  "Error occurred while fetching sweeps participation status";
export const pointsBalanceErrorMessage =
  "Error occurred while fetching user points";
export const activityHistoryErrorMessage =
  "Error occurred while fetching user activities";
export const pointsAdjustmentLogErrorMessage =
  "Error occurred while posting user point adjustment";
export const pointsAdjustmentLogSuccessMessage =
  "Points Adjustment has been awarded to the user";
export const sweepstakesErrorMessage =
  "Error occurred while fetching sweepstakes";
