import { createAsyncThunk } from "@reduxjs/toolkit";

import { getCSRRole, getStorageItem, getToken } from "../utils/storageUtils";
import { CARD_HOLDER_ID } from "../constants/storageKeys";
import { getActivityQueryParams, getApiHeaders } from "../utils/urlUtils";
import { POINTS_ADJUSTMENT_URI } from "../constants/apiUri";
import { baseApi, getApiDomain } from "../utils/apiUtils";
import {
  CSR_ROLE_HEADER,
  ONBOARDING_PAYLOAD,
  REQUEST_METHODS,
} from "../constants/appUtilsConstants";
import { updateIsActivePayperksUser } from "../store/slices/sessionSlice";
import { getActivityHistory, getPointsBalance } from "./dashboardThunks";

export const postPointsAdjustment = createAsyncThunk(
  "pointsAdjustment/postPointsAdjustment",
  async (
    { pointsOffered, reason, notes },
    { rejectWithValue, dispatch, getState }
  ) => {
    const externalUserId = getStorageItem(CARD_HOLDER_ID);
    const token = getToken();
    const headers = getApiHeaders(token);
    headers[CSR_ROLE_HEADER] = getCSRRole();

    // eslint-disable-next-line max-len
    const apiUri = `${getApiDomain()}${POINTS_ADJUSTMENT_URI}${externalUserId}/`;
    const requestBody = {
      points_offered: pointsOffered,
      reason,
      notes,
    };
    try {
      const response = await baseApi(
        apiUri,
        headers,
        REQUEST_METHODS.POST,
        requestBody
      );

      // eslint-disable-next-line no-unused-expressions
      reason === ONBOARDING_PAYLOAD.reason &&
        response.internal_status_code === 201 &&
        dispatch(updateIsActivePayperksUser());

      dispatch(getPointsBalance());
      const store = getState();
      const { orderSettings, pageSettings, filterSettings } =
        store.activity.filterDialog;
      const queryParams = getActivityQueryParams({
        orderSettings,
        pageSettings,
        filterSettings,
      });
      dispatch(getActivityHistory(queryParams));
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
