// store.js
import defaultPlugin from "store/plugins/defaults";
import dump from "store/plugins/dump";
import events from "store/plugins/events";
import expire from "store/plugins/expire";
import json2 from "store/plugins/json2";
import observe from "store/plugins/observe";
import operations from "store/plugins/operations";
import update from "store/plugins/update";
import engine from "store/src/store-engine";
import cookieStorage from "store/storages/cookieStorage";
import localStorage from "store/storages/localStorage";
import memoryStorage from "store/storages/memoryStorage";

import {
  IS_ACTIVE_PAYPERKS_USER,
  CSR_ROLE,
  JWT_KEY,
} from "../constants/storageKeys";
import { isTokenValid } from "./timeUtils";

// NOTE: browser cookie up to ~4kb
const allStorages = [localStorage, cookieStorage, memoryStorage];

const plugins = [
  defaultPlugin,
  dump,
  events,
  observe,
  expire,
  json2,
  operations,
  update,
];

const ppStore = engine.createStore(allStorages, plugins);

const setStorageItem = (key, val) => {
  ppStore.set(key, val);
};

const getStorageItem = key => ppStore.get(key, null);

const removeStorageItem = key => {
  ppStore.remove(key);
};

const clearUserStorageItems = () => {
  ppStore.each((val, key) => {
    removeStorageItem(key);
  });
};

const getToken = () => getStorageItem(JWT_KEY);

export const isValidSession = () => {
  const storedToken = getToken();
  return !!storedToken && isTokenValid(storedToken);
};

export const getCSRRole = () => getStorageItem(CSR_ROLE);

export const getIsActivePayperksUser = () =>
  getStorageItem(IS_ACTIVE_PAYPERKS_USER);

export default ppStore;
export {
  clearUserStorageItems,
  getStorageItem,
  removeStorageItem,
  setStorageItem,
  getToken,
};
