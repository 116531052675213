import { NavLink, useSearchParams } from "react-router-dom";
import propType from "prop-types";

import {
  DEFAULT_STRINGS,
  ERROR_MESSAGES,
  TAKE_HOMEPAGE_BUTTON,
} from "../constants/displayTexts";
import { DASHBOARD_URL } from "../constants/pageUrls";
import {
  ErrorBody,
  ErrorHeading,
  ErrorPageBackDropText,
  ErrorParagraph,
  ErrorSubHeading,
  TakeBackHomeButton,
} from "../styles/ErrorPageStyles";

const Error = ({ errorHeading, errorDetails, showButton }) => {
  const [searchParams] = useSearchParams();
  const errorState = searchParams.get("error");

  const errorText = ERROR_MESSAGES[errorState]
    ? ERROR_MESSAGES[errorState]
    : errorHeading;

  return (
    <ErrorBody>
      <ErrorPageBackDropText>
        <ErrorHeading>{DEFAULT_STRINGS.ERROR}</ErrorHeading>
      </ErrorPageBackDropText>
      <ErrorSubHeading>{errorText}</ErrorSubHeading>
      <ErrorParagraph>{errorDetails}</ErrorParagraph>

      {showButton && (
        <TakeBackHomeButton as={NavLink} to={`/${DASHBOARD_URL}`}>
          {TAKE_HOMEPAGE_BUTTON}
        </TakeBackHomeButton>
      )}
    </ErrorBody>
  );
};

Error.defaultProps = {
  errorHeading: DEFAULT_STRINGS.ERROR_OCCURRED,
  errorDetails: DEFAULT_STRINGS.ERROR_DETAIL,
  showButton: true,
};

Error.propTypes = {
  errorHeading: propType.string,
  errorDetails: propType.string,
  showButton: propType.bool,
};

export default Error;
