import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ErrorBoundary from "./components/common/ErrorBoundary";
import RootRouter from "./components/RootRouter";
import { appTheme } from "./utils/themes";

const App = () => (
  <CssBaseline>
    <ThemeProvider theme={appTheme}>
      <ErrorBoundary>
        <BrowserRouter>
          <RootRouter />
          <ToastContainer />
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  </CssBaseline>
);

export default App;
