import { styled } from "@mui/material/styles";

export const ErrorBody = styled("div")({
  position: "absolute",
  left: "50%",
  top: "50%",
  maxWidth: "920px",
  width: "100%",
  lineHeight: "1.4",
  textAlign: "center",
  paddingLeft: "15px",
  paddingRight: "15px",

  webkitTransform: "translate(-50%, -50%)",
  msTransform: "translate(-50%, -50%)",
  transform: "translate(-50%, -50%)",
});

export const ErrorPageBackDropText = styled("div")({
  position: "absolute",
  height: "100px",
  top: "0",
  left: "50%",
  zIndex: "-1",
});

export const ErrorHeading = styled("h1")(({ theme }) => ({
  color: "#ececec",
  fontWeight: "bolder",
  fontSize: "250px",
  margin: "0px",
  position: "absolute",
  left: "50%",
  top: "50%",
  webkitTransform: "translate(-50%, -50%)",
  msTransform: "translate(-50%, -50%)",
  transform: "translate(-50%, -50%)",

  [theme.breakpoints.down("xs")]: {
    fontSize: "90px",
  },
}));

export const ErrorSubHeading = styled("h2")(({ theme }) => ({
  fontWeight: "600",
  fontSize: "30px",
  margin: "0px",
  color: "royalblue",
  textTransform: "uppercase",
  [theme.breakpoints.down("xs")]: {
    fontSize: "15px",
    fontWeight: "400",
  },
}));

export const ErrorParagraph = styled("h2")(({ theme }) => ({
  color: "#000",
  fontWeight: "400",
  fontSize: "15px",
  marginTop: "15px",
  textTransform: "uppercase",
  [theme.breakpoints.down("xs")]: {
    fontSize: "10px",
    fontWeight: "200",
  },
}));

export const TakeBackHomeButton = styled("a")(({ theme }) => ({
  fontSize: "15px",
  textDecoration: "none",
  textTransform: "uppercase",
  background: "#189cf0",
  display: "inline-block",
  padding: "12px 25px",
  border: "2px solid transparent",
  borderRadius: "35px",
  color: "#fff",
  webkitTransition: "0.2s all",
  transition: "0.2s all",
  "&:hover": {
    background: "#fff",
    color: "#189cf0",
    borderColor: "#189cf0",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "10px",
    padding: "8px 20px",
    marginTop: "5px",
  },
}));
