import PropTypes from "prop-types";
import { TextField, Box, useTheme } from "@mui/material";
import camelCase from "lodash/camelCase";

import { TypographyCapitalizeStyles } from "../../../styles/commonStyles";
import {
  AutocompleteStyles,
  BoxOptionStyles,
} from "../../../styles/activityStyles";
import ActivityTypeMarker from "./ActivityTypeMarker";

const ActivityOptionFilter = ({
  id,
  label,
  filter,
  handleFilter,
  optionList,
}) => {
  const theme = useTheme();

  return (
    <Box>
      <TypographyCapitalizeStyles color={theme.palette.primary.main}>
        filter by {label}
      </TypographyCapitalizeStyles>
      <AutocompleteStyles
        id={id}
        size="small"
        value={filter}
        onChange={handleFilter}
        options={optionList}
        blurOnSelect={false}
        isOptionEqualToValue={(option, value) => option === value}
        renderOption={(props, option) => (
          <BoxOptionStyles
            component="li"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          >
            {id === "type-filter" && (
              <Box>
                <ActivityTypeMarker type={camelCase(option)} />
              </Box>
            )}
            {option}
          </BoxOptionStyles>
        )}
        renderInput={params => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </Box>
  );
};

ActivityOptionFilter.defaultProps = {
  filter: "",
};

ActivityOptionFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  filter: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  optionList: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default ActivityOptionFilter;
