import { Grid, Typography } from "@mui/material";
import { BeatLoader } from "react-spinners";
import propType from "prop-types";

import { DEFAULT_STRINGS } from "../constants/displayTexts";
import { CenterGrid } from "../styles/commonStyles";

const Loading = ({ loadingText }) => (
  <CenterGrid
    container
    alignItems="center"
    justifyContent="center"
    direction="column"
    spacing={1}
  >
    <Grid item>
      <BeatLoader color="blue" loading size="30px" />
    </Grid>
    <Grid item>
      <Typography color="primary" variant="h4" textAlign="center" gutterBottom>
        {loadingText}
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="overline">
        {DEFAULT_STRINGS.LOADING_DETAIL}
      </Typography>
    </Grid>
  </CenterGrid>
);
Loading.defaultProps = {
  loadingText: DEFAULT_STRINGS.LOADING,
};
Loading.propTypes = {
  loadingText: propType.string,
};

export default Loading;
