import { createAsyncThunk } from "@reduxjs/toolkit";

import { checkAuthQueryParams } from "../utils/apiUtils";
import { setStorageItem } from "../utils/storageUtils";
import {
  CARD_HOLDER_ID,
  CSR_ROLE,
  JWT_KEY,
  IS_ACTIVE_PAYPERKS_USER,
  PP_CSR_REFERRER,
} from "../constants/storageKeys";
import CSRApiError from "../utils/errorHandlerUtils";

export const authUser = createAsyncThunk("session/authUser", async query => {
  if (!checkAuthQueryParams(query)) throw new CSRApiError();
  const { jwt: token } = query;

  const {
    csr_role: csrRole,
    external_user_id: externalUserId,
    is_active_payperks_user: isActivePayperksUser,
    referrer,
  } = query;

  setStorageItem(JWT_KEY, token);
  setStorageItem(CARD_HOLDER_ID, externalUserId);
  setStorageItem(CSR_ROLE, csrRole);
  setStorageItem(IS_ACTIVE_PAYPERKS_USER, isActivePayperksUser === "True");
  setStorageItem(PP_CSR_REFERRER, referrer);
});
