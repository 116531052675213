import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import {
  DASHBOARD_URL,
  ERROR_URL,
  HOME_URL,
  LOGIN_URL,
  SWEEPSTAKES_URL,
  POINTS_ADJUSTMENT_URL,
} from "../constants/pageUrls";
import ActivityHistory from "../pages/ActivityHistory";
import Error from "../pages/Error";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import PrivateRoute from "./common/PrivateRoute";
import { sessionSelector } from "../store/slices/sessionSlice";
import Sweeps from "../pages/Sweeps";
import PointsAdjustment from "../pages/PointsAdjustment";
import { getCSRRole } from "../utils/storageUtils";
import { CSR_ROLES } from "../constants/appUtilsConstants";

const RootRouter = () => {
  const { isAuthed } = useSelector(sessionSelector);
  const csrRole = getCSRRole();

  return (
    <Routes>
      <Route path={HOME_URL}>
        <Route index exact element={<Navigate to={DASHBOARD_URL} replace />} />
        <Route
          exact
          path={DASHBOARD_URL}
          element={
            <PrivateRoute isAuthed={isAuthed} component={<ActivityHistory />} />
          }
        />
        <Route
          exact
          path={SWEEPSTAKES_URL}
          element={<PrivateRoute isAuthed={isAuthed} component={<Sweeps />} />}
        />
        <Route
          exact
          path={POINTS_ADJUSTMENT_URL}
          element={
            <PrivateRoute
              isAuthed={isAuthed && csrRole === CSR_ROLES.administrator}
              component={<PointsAdjustment />}
            />
          }
        />
        <Route exact path={LOGIN_URL} element={<Login />} />
        <Route exact path={ERROR_URL} element={<Error />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default RootRouter;
