import { createSlice } from "@reduxjs/toolkit";

import { PAGE_SETTINGS, REQ_STATUSES } from "../../constants/appUtilsConstants";
import { sweepstakesErrorMessage } from "../../constants/toastMessages";
import { raiseErrorToast } from "../../utils/toastUtils";
import { getSweepstakes } from "../../thunks/sweepstakesThunk";

const initialState = {
  currentPage: 0,
  sweepstakesCount: 0,
  sweepsData: [],
  status: REQ_STATUSES.idle,
  pageSettings: PAGE_SETTINGS,
};

const sweepstakesPending = state => ({
  ...state,
  status: REQ_STATUSES.loading,
});

const sweepstakesFulfilled = (
  state,
  {
    internal_status_code: statusCode,
    data: {
      sweeps,
      current_page_number: currentPageNumber,
      total_sweepstakes_count: totalSweepstakesCount,
    },
  }
) => {
  if (statusCode === 200) {
    return {
      ...state,
      sweepsData: sweeps,
      currentPage: currentPageNumber,
      sweepstakesCount: totalSweepstakesCount,
      status: REQ_STATUSES.succeeded,
    };
  }
  state.status = REQ_STATUSES.failed;
  raiseErrorToast(sweepstakesErrorMessage);
  return state;
};

const sweepstakesRejected = state => {
  state.status = REQ_STATUSES.failed;
  raiseErrorToast(sweepstakesErrorMessage);
};

const sweepstakesSlice = createSlice({
  name: "sweepstakes",
  initialState,
  reducers: {
    updatePageNumber: (state, { payload }) => ({
      ...state,
      pageSettings: {
        ...state.pageSettings,
        page: payload,
      },
    }),
    updatePageSize: (state, { payload }) => ({
      ...state,
      pageSettings: {
        perPage: payload,
        page: 1,
      },
    }),
  },
  extraReducers: {
    [getSweepstakes.pending]: state => sweepstakesPending(state),
    [getSweepstakes.fulfilled]: (state, { payload }) =>
      sweepstakesFulfilled(state, payload),
    [getSweepstakes.rejected]: state => sweepstakesRejected(state),
  },
});

export const sweepstakesSelector = state => state.sweepstakes;
export const { updatePageNumber, updatePageSize } = sweepstakesSlice.actions;
export default sweepstakesSlice.reducer;
