const getPrizePoolSum = prizes =>
  prizes.reduce(
    (
      totalPrize,
      {
        value_in_dollars: ValueInDollars,
        number_of_prizes_to_award: NumberOfPrizes,
      }
    ) => totalPrize + ValueInDollars * NumberOfPrizes,
    0
  );

export { getPrizePoolSum };
