import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import DashboardCard from "./DashboardCard";
// eslint-disable-next-line max-len
import { totalWinningsSelector } from "../../../store/slices/totalWinningsSlice";
import { getTotalWinnings } from "../../../thunks/dashboardThunks";
import { REQ_STATUSES } from "../../../constants/appUtilsConstants";
import { TotalWinningsCardStyles } from "../../../styles/dashboardCardStyles";

const TotalWinningsCard = () => {
  const dispatch = useDispatch();

  const { status, totalWinnings } = useSelector(totalWinningsSelector);

  useEffect(() => {
    if (!(status === REQ_STATUSES.succeeded)) {
      dispatch(getTotalWinnings());
    }
  }, []);

  return (
    <DashboardCard
      status={status}
      cardIcon={<TotalWinningsCardStyles />}
      cardHeadingText="Lifetime Winnings"
      cardBodyText={`$${totalWinnings}`}
    />
  );
};

export default TotalWinningsCard;
