import {
  Box,
  Dialog,
  DialogTitle,
  Autocomplete,
  TableCell,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const FilterStyles = styled(Box)(() => ({
  position: "relative",
  padding: "5%",
}));

export const DialogStyles = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    width: "300px",
  },
}));

export const DialogTitleStyles = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.backgroundColor,
}));

export const BoxDateStyles = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  "& > div": { width: "100%" },
}));

export const BoxOptionStyles = styled(Box)(({ theme }) => ({
  "& > div": {
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
}));

export const AutocompleteStyles = styled(Autocomplete)(({ theme }) => ({
  marginTop: theme.spacing(1.25),
  marginBottom: theme.spacing(1.25),
}));

export const TableSubCellStyles = styled(TableCell)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
}));
