import { Toolbar } from "@mui/material";

import {
  AppBarStyles,
  NavLinkStyles,
  BoxStyles,
} from "../../styles/NavBarStyles";
import {
  ADMIN_NAV_LINKS,
  CSR_ROLES,
  NAV_LINKS,
} from "../../constants/appUtilsConstants";
import { getCSRRole } from "../../utils/storageUtils";

const NavBar = () => {
  const navBarLinks =
    getCSRRole() === CSR_ROLES.administrator
      ? NAV_LINKS.concat(ADMIN_NAV_LINKS)
      : NAV_LINKS;

  return (
    <AppBarStyles>
      <Toolbar>
        <BoxStyles>
          {navBarLinks.map(({ id, title, link }) => (
            <NavLinkStyles key={id} to={link}>
              {title}
            </NavLinkStyles>
          ))}
        </BoxStyles>
      </Toolbar>
    </AppBarStyles>
  );
};

export default NavBar;
