import { Container, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import NavBar from "./NavBar";
import SweepsStatusCard from "../Dashboard/DashboardCards/SweepsStatusCard";
import TotalWinningsCard from "../Dashboard/DashboardCards/TotalWinningsCard";
import WelcomePointsCard from "../Dashboard/DashboardCards/WelcomePointsCard";
// eslint-disable-next-line max-len
import ActivityHistoryPoints from "../Dashboard/DashboardCards/ActivityPointsCard";
import { sessionSelector } from "../../store/slices/sessionSlice";
import { ERROR_URL } from "../../constants/pageUrls";

const Layout = ({ children, pageTitle }) => {
  const { isAuthed, isActivePayperksUser } = useSelector(sessionSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthed) {
      navigate(`/${ERROR_URL}`, { replace: true });
    }
  }, [isAuthed]);

  const CARDS = [SweepsStatusCard, TotalWinningsCard, ActivityHistoryPoints];
  // eslint-disable-next-line no-unused-expressions
  !isActivePayperksUser && CARDS.unshift(WelcomePointsCard);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <NavBar />
      <Container maxWidth="xl">
        <Grid container spacing={3} marginTop="2%">
          <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
            {CARDS.map(Card => (
              <Card />
            ))}
          </Grid>
          <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

export default Layout;
