import { useEffect, useRef } from "react";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { PAGE_TITLES } from "../constants/appUtilsConstants";
import Layout from "../components/common/Layout";
// eslint-disable-next-line max-len
import ActivityTableBar from "../components/Dashboard/activity/ActivityTableBar";
// eslint-disable-next-line max-len
import ActivityTableContainer from "../components/Dashboard/activity/ActivityTableContainer";
import { getActivityQueryParams } from "../utils/urlUtils";
import { getActivityHistory } from "../thunks/dashboardThunks";
import {
  activityHistoryDataSelector,
  filterDialogSelector,
} from "../store/slices/activityHistorySlice";

const ActivityHistory = () => {
  const dispatch = useDispatch();
  const { orderSettings, pageSettings, filterSettings } =
    useSelector(filterDialogSelector);

  const firstUpdate = useRef(true);
  const { page } = pageSettings;
  const activityHistoryData = useSelector(activityHistoryDataSelector(page));

  useEffect(() => {
    if (!activityHistoryData) {
      const queryParams = getActivityQueryParams({
        orderSettings,
        pageSettings,
        filterSettings,
      });
      dispatch(getActivityHistory(queryParams));
    }
  }, [pageSettings]);

  useEffect(() => {
    if (!firstUpdate.current) {
      const queryParams = getActivityQueryParams({
        orderSettings,
        pageSettings,
        filterSettings,
      });
      dispatch(getActivityHistory(queryParams));
    }
    firstUpdate.current = false;
  }, [orderSettings]);

  return (
    <Layout pageTitle={PAGE_TITLES.dashboard}>
      <Paper variant="outlined">
        <ActivityTableBar />
        <ActivityTableContainer />
      </Paper>
    </Layout>
  );
};

export default ActivityHistory;
