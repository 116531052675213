import { Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import DashboardCard from "./DashboardCard";
import {
  DATE_FORMAT,
  REQ_STATUSES,
  SWEEPS_PARTICIPATION_STATUS,
} from "../../../constants/appUtilsConstants";
// eslint-disable-next-line max-len
import { sweepsParticipationStatusSelector } from "../../../store/slices/sweepsParticipationStatusSlice";
import { getSweepsParticipationStatus } from "../../../thunks/dashboardThunks";
import { SweepsIconStyles } from "../../../styles/dashboardCardStyles";

const SweepsStatusCard = () => {
  const dispatch = useDispatch();

  const { status, sweepsParticipationStatus, lastUpdatedOn } = useSelector(
    sweepsParticipationStatusSelector
  );

  useEffect(() => {
    if (!(status === REQ_STATUSES.succeeded)) {
      dispatch(getSweepsParticipationStatus());
    }
  }, []);

  const isOptIn =
    sweepsParticipationStatus === SWEEPS_PARTICIPATION_STATUS.optedIn;
  const backgroundColor = isOptIn ? green[200] : red[200];
  const cardBackgroundColor = isOptIn ? green[500] : red.A700;
  const subString = isOptIn
    ? SWEEPS_PARTICIPATION_STATUS.optedIn
    : SWEEPS_PARTICIPATION_STATUS.optedOut;
  const cardBodyText = isOptIn ? "Opted In" : "Opted Out";

  return (
    <DashboardCard
      status={status}
      backgroundcolor={backgroundColor}
      cardIcon={<SweepsIconStyles backgroundcolor={cardBackgroundColor} />}
      cardHeadingText="Sweeps Opt-in status"
      cardBodyText={cardBodyText}
      cardFooterElement={
        <Typography variant="secondary">
          {lastUpdatedOn &&
            `${subString} on ${format(new Date(lastUpdatedOn), DATE_FORMAT)}
             `}
        </Typography>
      }
    />
  );
};

export default SweepsStatusCard;
