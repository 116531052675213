import { AppBar, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { grey } from "@mui/material/colors";

export const AppBarStyles = styled(AppBar)(() => ({
  backgroundColor: grey[900],
  position: "static",
}));

export const BoxStyles = styled(Box)(() => ({
  flexGrow: 1,
}));

export const NavLinkStyles = styled(Link)(({ theme }) => ({
  color: "white",
  textDecoration: "none",
  margin: 15,
  textAlign: "start",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
}));
