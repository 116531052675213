/* eslint-disable indent */
import { useState } from "react";
import PropTypes from "prop-types";
import startCase from "lodash/startCase";
import { format } from "date-fns";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Collapse,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { TypographyCapitalizeStyles } from "../../../styles/commonStyles";
import {
  ACTIVITY_SUB_TABLE_HEADERS,
  DATE_FORMAT,
} from "../../../constants/appUtilsConstants";
import { TableSubCellStyles } from "../../../styles/activityStyles";

const ActivityTableRow = ({
  row: {
    activity_id: activityId,
    activity_category_i18n: activityCategoryI18n,
    activity_type: activityType,
    points,
    date_activity_happened: dateActivityHappened,
    activity_message_i18n: activityMessageI18n,
    hidden,
    additional_information: additionalInformation,
    admin_notes: adminNotes,
  },
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TableRow key={`main-${activityId}`}>
        <TableCell align="left" width={20}>
          <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" width={40}>
          {activityId}
        </TableCell>
        <TableCell align="center" width={300}>
          {startCase(activityType)}
        </TableCell>
        <TableCell align="center" width={300}>
          {format(new Date(dateActivityHappened), DATE_FORMAT)}
        </TableCell>
        <TableCell align="center">{points}</TableCell>
        <TableCell align="center">
          <TypographyCapitalizeStyles>
            {hidden.toString()}
          </TypographyCapitalizeStyles>
        </TableCell>
      </TableRow>
      {/* sub table */}
      <TableRow key={`sub-${activityId}`}>
        <TableSubCellStyles colSpan={6}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box marginTop={2}>
              <TypographyCapitalizeStyles
                variant="h6"
                gutterBottom
                component="div"
              >
                additional information
              </TypographyCapitalizeStyles>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell align="left" variant="head">
                      <TypographyCapitalizeStyles fontWeight={600}>
                        activity message
                      </TypographyCapitalizeStyles>
                    </TableCell>
                    <TableCell
                      align="left"
                      variant="body"
                      dangerouslySetInnerHTML={{
                        __html: activityMessageI18n,
                      }}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" variant="head">
                      <TypographyCapitalizeStyles fontWeight={600}>
                        activity category
                      </TypographyCapitalizeStyles>
                    </TableCell>
                    <TableCell
                      align="left"
                      variant="body"
                      dangerouslySetInnerHTML={{
                        __html: activityCategoryI18n,
                      }}
                    />
                  </TableRow>
                  {additionalInformation &&
                    Object.entries(ACTIVITY_SUB_TABLE_HEADERS).map(
                      ([, { id, label }]) =>
                        additionalInformation[id] && (
                          <TableRow key={id}>
                            <TableCell align="left" variant="head">
                              <TypographyCapitalizeStyles fontWeight={600}>
                                {label}
                              </TypographyCapitalizeStyles>
                            </TableCell>
                            <TableCell align="left" variant="body">
                              {ACTIVITY_SUB_TABLE_HEADERS.postedDate.id === id
                                ? format(
                                    new Date(additionalInformation[id]),
                                    DATE_FORMAT
                                  )
                                : additionalInformation[id]}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  {adminNotes && (
                    <TableRow key="internal_notes">
                      <TableCell align="left" variant="head">
                        <TypographyCapitalizeStyles fontWeight={600}>
                          admin notes
                        </TypographyCapitalizeStyles>
                      </TableCell>
                      <TableCell align="left" variant="body">
                        {adminNotes}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableSubCellStyles>
      </TableRow>
    </>
  );
};

ActivityTableRow.propTypes = {
  row: PropTypes.shape({
    activity_id: PropTypes.number.isRequired,
    activity_category_i18n: PropTypes.string.isRequired,
    activity_type: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    date_activity_happened: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
    activity_message_i18n: PropTypes.string.isRequired,
    admin_notes: PropTypes.string,
    additional_information: PropTypes.shape({
      transaction_code: PropTypes.string,
      galileo_program_id: PropTypes.number,
      authorization_code: PropTypes.number,
      posted_date: PropTypes.string,
      auth_sequence: PropTypes.number,
    }),
  }).isRequired,
};

export default ActivityTableRow;
