import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import PropTypes from "prop-types";
import { format } from "date-fns";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import {
  DATE_FORMAT,
  SWEEPSTAKES_SUB_TABLE_HEADERS,
} from "../../constants/appUtilsConstants";
import { TypographyCapitalizeStyles } from "../../styles/commonStyles";
import { getPrizePoolSum } from "../../utils/sweepsUtils";
import { TableSubCellStyles } from "../../styles/activityStyles";
import TableHeaders from "../common/TableHeaders";

const SweepTableRow = ({
  row: {
    id,
    max_points_per_user: maxPointsPerUser,
    date_earning_period_starts: dateEarningPeriodStarts,
    date_earning_period_ends: dateEarningPeriodEnds,
    sweeps_stage: sweepsStage,
    prizes,
  },
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TableRow key={`main-${id}`}>
        <TableCell align="left" width={20}>
          <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" width={40}>
          {id}
        </TableCell>
        <TableCell align="center" width={200}>
          <TypographyCapitalizeStyles>{sweepsStage}</TypographyCapitalizeStyles>
        </TableCell>
        <TableCell align="center" width={200}>
          {format(new Date(dateEarningPeriodStarts), DATE_FORMAT)}
        </TableCell>
        <TableCell align="center" width={200}>
          {format(new Date(dateEarningPeriodEnds), DATE_FORMAT)}
        </TableCell>
        <TableCell align="center">{getPrizePoolSum(prizes)}</TableCell>
        <TableCell align="center">{maxPointsPerUser}</TableCell>
      </TableRow>
      {/* sub table for prizes*/}
      <TableRow key={`sub-${id}`}>
        <TableSubCellStyles colSpan={8}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box marginTop={2}>
              <TypographyCapitalizeStyles
                variant="h6"
                gutterBottom
                component="h6"
              >
                prizes
              </TypographyCapitalizeStyles>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaders headers={SWEEPSTAKES_SUB_TABLE_HEADERS} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prizes.map(prize => (
                    <TableRow key={prize.id}>
                      <TableCell align="center">
                        {prize.value_in_dollars}
                      </TableCell>
                      <TableCell align="center">
                        {prize.number_of_prizes_to_award}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableSubCellStyles>
      </TableRow>
    </>
  );
};

SweepTableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sweeps_stage: PropTypes.string.isRequired,
    max_points_per_user: PropTypes.number.isRequired,
    date_earning_period_starts: PropTypes.string.isRequired,
    date_earning_period_ends: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    prizes: PropTypes.array.isRequired,
  }).isRequired,
};

export default SweepTableRow;
