import React from "react";
import { Navigate } from "react-router-dom";

import { ERROR_URL } from "../../constants/pageUrls";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error);
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  // }
  render() {
    const { hasError } = this.state;
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;

    if (hasError) {
      return <Navigate to={`/${ERROR_URL}`} replace />;
    }

    return children;
  }
}

export default ErrorBoundary;
