export const ERROR_STATES = {
  LOGIN_SECONDARY_CARD_ERROR_MESSAGE: "secondary_card",
  LOGIN_UNDER_AGE_ERROR_MESSAGE: "under_age",
  LOGIN_INACTIVE_SITE_ERROR_MESSAGE: "inactive_site",

  AUTH_SESSION_INVALID: "invalid_auth_session",
};

export const ERROR_MESSAGES = {
  [ERROR_STATES.LOGIN_SECONDARY_CARD_ERROR_MESSAGE]:
    "PayPerks is not enabled for secondary cardholders with non-shared " +
    "balances",
  [ERROR_STATES.LOGIN_UNDER_AGE_ERROR_MESSAGE]:
    "PayPerks is not enabled for users under 18",
  [ERROR_STATES.LOGIN_INACTIVE_SITE_ERROR_MESSAGE]:
    "PayPerks is not enabled for your state",

  [ERROR_STATES.AUTH_SESSION_INVALID]:
    "Seems like your session is invalid or has expired.",

  API_ERROR: "An error has occurred.",
  AUTH_CLIENTSIDE_ERROR: "Client side error occurred",
  AUTH_CLIENTSIDE_AUTH__JWT_ERROR:
    "Client side error occurred: missing JWT in URL",
  CATCH_ALL_ERRORS: "Uh oh, it seems that something has gone wrong on our end.",

  LOGIN_LOADING: "Please wait, while your session in being Authenticated.",

  REQUIRED_FIELD: "This field is required.",
  POINTS_ADJUSTMENT_MIN_POINTS: "Awarded points should be at least 1.",
  POINTS_ADJUSTMENT_REASON_REQUIRED: "Please select the reason for adjustment",
};

export const DEFAULT_STRINGS = {
  ERROR: "Error",
  ERROR_OCCURRED: "An error has occurred.",
  ERROR_DETAIL: "We apologize for the inconvenience",
  LOADING: "Please wait while we load.",
  LOADING_DETAIL: "Hang tight we are loading.",
};
export const TAKE_HOMEPAGE_BUTTON = "Back to Homepage";

export const POINTS_ADJUSTMENT_REASONS = [
  {
    id: 1,
    value: "Missing point of sale activity",
    key: "missing-pos",
  },
  { id: 2, value: "Miscellaneous apology", key: "misc-apology" },
  {
    id: 3,

    value:
      // eslint-disable-next-line max-len
      "Complaint from Non Shared Secondary account and give extra points after explanation",
    key: "non-shared-secondary-complaint",
  },
  { id: 4, value: "Other", key: "other" },
];
