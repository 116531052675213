import PropTypes from "prop-types";
import { TableCell, TableSortLabel } from "@mui/material";

import { TypographyCapitalizeStyles } from "../../styles/commonStyles";
import { ORDER_SETTINGS, ORDER_TYPE } from "../../constants/appUtilsConstants";

const TableHeaderWithSortEnabled = ({
  id,
  label,
  orderSettings: { orderBy, order },
  createOrderHandler,
  isEnabled,
}) => {
  const { ASCENDING } = ORDER_TYPE;
  return isEnabled ? (
    <TableSortLabel
      active={orderBy === id}
      direction={orderBy === id ? order : ASCENDING}
      onClick={createOrderHandler(id)}
    >
      <TypographyCapitalizeStyles fontWeight={600}>
        {label}
      </TypographyCapitalizeStyles>
    </TableSortLabel>
  ) : (
    <TypographyCapitalizeStyles fontWeight={600}>
      {label}
    </TypographyCapitalizeStyles>
  );
};

TableHeaderWithSortEnabled.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  orderSettings: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  }).isRequired,
  createOrderHandler: PropTypes.func.isRequired,
};

const TableHeaders = ({ orderSettings, onRequestOrder, headers }) => {
  const createOrderHandler = property => event => {
    onRequestOrder(event, property);
  };
  return (
    <>
      {Object.entries(headers).map(([, { id, label, sortEnabled }]) => (
        <TableCell align="center" key={id}>
          <TableHeaderWithSortEnabled
            id={id}
            orderSettings={orderSettings}
            createOrderHandler={createOrderHandler}
            label={label}
            isEnabled={sortEnabled}
          />
        </TableCell>
      ))}
    </>
  );
};

TableHeaders.defaultProps = {
  onRequestOrder: () => {},
  orderSettings: ORDER_SETTINGS,
};

TableHeaders.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  headers: PropTypes.object.isRequired,
  orderSettings: PropTypes.shape({
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  }),
  onRequestOrder: PropTypes.func,
};

export default TableHeaders;
