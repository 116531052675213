import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";

import DashboardCard from "./DashboardCard";
import { postPointsAdjustment } from "../../../thunks/pointsAdjustment";
import { OnboardingIconStyles } from "../../../styles/dashboardCardStyles";
import { IS_ACTIVE_PAYPERKS_USER } from "../../../constants/storageKeys";
import { setStorageItem } from "../../../utils/storageUtils";
import { ONBOARDING_PAYLOAD } from "../../../constants/appUtilsConstants";

const WelcomePointsCard = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(postPointsAdjustment(ONBOARDING_PAYLOAD));
    setStorageItem(IS_ACTIVE_PAYPERKS_USER, true);
  };

  return (
    <DashboardCard
      cardIcon={<OnboardingIconStyles />}
      cardHeadingText="Award Welcome Points"
      cardBodyText={
        <Button variant="contained" onClick={handleClick}>
          award points
        </Button>
      }
      cardFooterElement=""
    />
  );
};

export default WelcomePointsCard;
