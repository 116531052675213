// custom API Error
import { ERROR_MESSAGES } from "../constants/displayTexts";

class CSRApiError extends Error {
  constructor(
    errorResponseObj = {},
    errorMessage = ERROR_MESSAGES.API_ERROR,
    ...params
  ) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CSRApiError);
    }

    this.name = "CSRApiError";
    this.response = errorResponseObj;
    this.message = errorMessage;
  }
}

export default CSRApiError;
