/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { TableCell, TableRow, Skeleton } from "@mui/material";

import { TypographyCapitalizeStyles } from "../../styles/commonStyles";

const TableSkeleton = Component => {
  const EnhancedComponent = ({ rows, colSpan, isLoading, ...props }) =>
    isLoading ? (
      <TableRow>
        <TableCell colSpan={colSpan}>
          {[...Array(rows)].map((_, index) => (
            <Skeleton
              // eslint-disable-next-line react/no-array-index-key
              key={`skeleton-${index}`}
              width="100%"
              height={30}
              variant="rectangular"
              sx={{ my: 2 }}
              animation="wave"
            />
          ))}
        </TableCell>
      </TableRow>
    ) : (
      <Component {...props} />
    );

  EnhancedComponent.propTypes = {
    rows: PropTypes.number.isRequired,
    colSpan: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  return EnhancedComponent;
};

const TableContent = ({ CustomTableRow, data, id }) =>
  data?.length > 0 ? (
    data.map(row => <CustomTableRow row={row} key={row[id]} />)
  ) : (
    <TableRow>
      <TableCell align="center" colSpan={8}>
        <TypographyCapitalizeStyles>
          no records found
        </TypographyCapitalizeStyles>
      </TableCell>
    </TableRow>
  );

TableContent.propTypes = {
  CustomTableRow: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
};

const TableContentContainer = TableSkeleton(TableContent);

TableContentContainer.propTypes = {
  CustomTableRow: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  rows: PropTypes.number.isRequired,
  colSpan: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
};

export default TableContentContainer;
